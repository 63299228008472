var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-app",
      class: { "c-dark-theme": _vm.$store.getters.darkMode },
    },
    [
      _c(
        "CWrapper",
        { staticClass: "wrapper" },
        [
          _c("Header"),
          _vm.$store.getters["user/getPermissions"]("view_run_line") &&
          _vm.showLineRunner
            ? _c("LineRunner")
            : _vm._e(),
          _c("div", { staticClass: "c-body" }, [
            _c(
              "main",
              { staticClass: "c-main" },
              [
                _c(
                  "CContainer",
                  { staticClass: "container-custom", attrs: { fluid: "" } },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "out-in" } },
                      [_c("router-view")],
                      1
                    ),
                    _vm.isBrave
                      ? _c("portal-target", {
                          attrs: { name: "brave-notify-modal" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }